// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@import '~@angular/material/theming';
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat-core();

@import 'styles/normalize.css';
@import 'styles/variables';
@import 'styles/mixins';
@import 'styles/list';
@import 'styles/search-data';
@import 'styles/modal';
@import 'styles/file-upload';
@import 'styles/version-management';

$mat-myapp-primary: (
  50 : $tiffany-blue,
  contrast: (
    50: #fff
  )
);

$mat-myapp-accent: (
  50 : $primary,
  contrast: (
    50 : #fff,
  )
);

$mat-myapp-accent: (
  50 : $accent,
  contrast: (
    50 : #fff,
  )
);


$mat-myapp-warn: (
  50 : $danger,
  contrast: (
    50 : #fff,
  )
);

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$rea-admin-primary: mat-palette($mat-myapp-primary, 50);
$rea-admin-accent: mat-palette($mat-myapp-accent, 50);

// The warn palette is optional (defaults to red).
$rea-admin-warn: mat-palette($mat-myapp-warn, 50);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$rea-admin-theme: mat-light-theme((color: (
  primary: $rea-admin-primary,
  accent: $rea-admin-accent,
  warn: $rea-admin-warn,
)));


// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include angular-material-theme($rea-admin-theme);

/* You can add global styles to this file, and also import other style files */

// html, body { height: 100%; }


html {
  font: 400 16px Roboto, "Helvetica Neue", sans-serif;
}

.mat-body, .mat-body-1, .mat-typography {
  letter-spacing: normal;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}


a {
  text-decoration: none;
  color: $primary;
  cursor: pointer;
}

.full-height {
  height: 100%;
}

.underlined-button {
  cursor: pointer;
  text-decoration: underline;
  font-style: italic;
}

.redirection-link {
  text-decoration: underline;
  font-weight: 500;
}

// Allow wrapped components to be styled from client code
.facade {
  width: inherit !important;

  .outline-fix {
    position: relative;
    bottom: 4px;
  }
}

.logo {
  font-size: 25px;
  font-weight: 600;
  color: $primary;
  letter-spacing: 2px;
}

.mat-snack-bar-container {
  background: #ebebeb;

  &.success {
    color: green;
    border: 1px solid green;
  }

  &.warning {
    color: orange;
    border: 1px solid orange;
  }

  &.error {
    color: red;
    border: 1px solid red;
  }
}

//scrollbar
::-webkit-scrollbar {
  width: 6px;
  height: 8px;
  overflow-y: auto;
}

::-webkit-scrollbar-thumb {
  background: $grey;
  border-radius: 10px;
}

::-webkit-scrollbar-track {
  background: $snow;
  border-radius: 10px;
}

// Allow page to be scrollable when a modal pops up
.cdk-global-overlay-wrapper {
  overflow-y: auto;
  z-index: auto;

  .cdk-overlay-pane {
    max-width: 90vw !important;
    overflow-x: inherit;
  }
}

.home .mat-progress-bar {
  position: absolute;
  height: 2px;
  z-index: 3;

  .mat-progress-bar-background {
    display: none;
  }
}

.custom-snackbar {
  width: 100vw;
  background: $snow;
  font-weight: 500;

  &.mat-snack-bar-container {
    box-shadow: 0px 3px 5px -1px rgb(0 0 0 / 18%), 0px 6px 10px 0px rgb(0 0 0 / 0%), 0px 1px 18px 0px rgb(0 0 0 / 7%);
  }

}

.mat-form-field-appearance-outline .mat-form-field-flex {

  .mat-form-field-infix {
    padding: 0.9em 0 0.6em 0;
    border-top: none;
  }

  .mat-form-field-prefix, .mat-form-field-suffix {
    top: 0.5em;
  }

  .mat-form-field-prefix {
    padding-right: 10px;
  }
}


.mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label {
  transform: translateY(-1em) scale(0.7);
}


.mat-card:not([class*=mat-elevation-z]), .mat-expansion-panel:not([class*=mat-elevation-z]) {
  @include container-shadow;
}

.result-container {
  @include container-shadow;
}

.page-wrapper {
  padding: 2em;
  height: 100%;
}

.error-message {
  color: $danger;
}

.accent {
  color: $accent;
}

.primary {
  color: $primary;
}

.full-width-input {
  width: 100%;
}

.mat-button-focus-overlay {
  background-color: transparent !important;
}

//  flags in tenant dropdown
.rounded-border-flag {
  border-radius: 2px;
  box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.2);
}

.tenants-dropdown {
  display: flex;
  align-items: center;
}

.flag-spacing {
  margin-right: 1em;
  margin-left: 2px;
}

.mat-select-panel .mat-option.mat-selected:not(.mat-option-multiple) {
  background: rgba(0, 0, 0, 0.08);
}

.mat-option {
  font-size: .8rem;
}

.mat-autocomplete-panel.mat-autocomplete-visible {
  margin-top: -3.5%;
  margin-left: 2%;
}


.message-card {
  letter-spacing: .05rem;
  display: flex;
  align-items: center;
  color: $hoki;
  background: rgba($hoki, .1);
  padding: 1em;
  font-size: 1rem;
  margin-bottom: 2em;

  mat-icon {
    margin-right: .7em;
    font-size: 1.7rem;
    margin-top: -.25em;
  }

  &.bg-danger {
    background: rgba($danger, .15);
  }
}

.sticky {
  position: fixed;
}

.flex-row {
  flex-direction: row !important;
  align-items: center;
}

.overflow-auto {
  overflow: auto;
}

.centered-content-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10vh;
}

.truncate {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  height: 110%;
  text-overflow: ellipsis;
}

// ngx-guided-tour styling

$tour-step-color: #018BA0;
$tour-next-button-hover: $snow;
$tour-skip-link-color: #C2DEE1;
$tour-text-color: $snow;
$tour-zindex: 3;

@import '../node_modules/ngx-guided-tour/scss/guided-tour-base-theme.scss';

.tour-step .tour-block {
  border-radius: 5px;

  .tour-buttons .next-button {
    line-height: 31px;
    padding: 0 16px;
    border-radius: 4px;

    &:hover {
      background-color: $primary;
    }
  }
}

ngx-guided-tour .tour-step {
  position: relative;
}

ngx-guided-tour .tour-step .tour-block .tour-title {
  font-weight: 500 !important;
  padding: 0;
}

ngx-guided-tour .tour-step .tour-buttons {
  font-weight: 500 !important;


  .next-button {
    background-color: #EBEBEB !important;
    color: $atomic;

    &:hover {
      color: $atomic;
      background-color: $snow !important;
    }
  }
}

ngx-guided-tour .tour-step .tour-progress-indicator {
  position: absolute;
  bottom: 1em;
}

ngx-guided-tour .tour-step .tour-buttons button.link-button {
  &.back-button {
    background-color: #018BA0;
    border: 1px solid $snow;
    color: $snow;
    border-radius: 4px;
    font-weight: 400;
    height: 31px;
    margin-right: 20px;

    &:hover {
      background-color: #EBEBEB;
      color: #018BA0;
    }
  }

  &.skip-button {
    position: absolute;
    top: .5em;
    right: 1em;

    &:after {
      font-weight: 400 !important;
      cursor: pointer;
      position: absolute;
      font-family: "Material Icons";
      content: "\e5cd";
      font-size: 20px;
    }

  }
}

.mat-tooltip {
  font-size: .75rem;
}

// Angular Material table sorting arrow displayed by default
.mat-sort-header-container:not(.mat-sort-header-sorted) .mat-sort-header-arrow {
  opacity: 0.54 !important;
  transform: translateY(0px) !important;
}

// end ngx-guided-tour styling



.mat-typography {
  h1 {
    font-size: 1.5rem
  }

  h2 {
    font-size: 1.25rem
  }

  h3 {
    font-size: 1rem
  }

  h4 {
    font-size: .9rem
  }
}

@media only screen and (min-width: $breakpoint-fhd) {

  html {
    font-size: 18px;
  }
}

@media only screen and (max-width: $breakpoint-xxl) {

  html {
    font-size: 16px;
  }
}

@media only screen and (max-width: $breakpoint-lg) {

  html {
    font-size: 15px;
  }
}

@media only screen and (max-width: $breakpoint-md) {
  .page-wrapper {
    padding: 1em;
  }
}

