
// variables

$tiffany-blue: #4DC4D3;
$tangerine-yellow: #f8cb00;
$medium-sea-green: #4dbd74;
$fruit-salad: #3a9d5d;
$oxford-blue: #263238;
$atomic: #314751;
$black-pearl: #1E282D;
$heather: #aab8bf;
$gainsboro: #e0e0e0;
$hoki: #607D8B;
$grey: #7f7f7f;
$pink-swan: #B3B3B3;
$pattens-blue: #e1e3e4;
$hawkes-blue: #d8dfe8;
$white: #ffffff;
$tomato: #f95b50;
$red-orange: #f44336;
$snow: #fafafa;
$alice-blue: #f7fafd;
$white-smoke: #F5F5F5;

// theme variables
$primary: $tiffany-blue;
$danger: $tomato;
$accent: $tangerine-yellow;
$success: $medium-sea-green;
$background: $snow;

$secondary-font: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;

// media queries breakpoints
$breakpoint-xs: 365px;
$breakpoint-sm: 576px;
$breakpoint-md: 768px;
$breakpoint-lg: 1024px;
$breakpoint-xl: 1200px;
$breakpoint-xxl: 1500px;
$breakpoint-fhd: 1920px;
