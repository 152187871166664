@import '_variables.scss';

.modal-wrapper {
  padding: 1em;

  .modal-content {
    padding: 1.5em 0;
    max-width: 33vw;
    min-width: 20vw;
  }

  .primary button{
    color: $white;
    background: $primary;
  }

  .warn button {
    color: $white;
    background: $danger;
  }


  .neutral button {
    background: $pattens-blue;
  }

  .cancel-button button{
    background: $pattens-blue;
    margin-left: 1em;
  }
}

