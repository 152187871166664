@import "~@angular/material/theming";
@import "mixins";

.template-management {
  display: grid;
  grid-gap: 1em;
  grid-template-columns: 5fr 2fr;
  grid-template-areas:
    "drafts right-column";

  .right-column {
    grid-area: right-column;
    display: flex;
    gap: 1em;
    flex-direction: column;
  }

  .drafts {
    grid-area: drafts;

    .drafts-header {
      background: #f8cb00;
      padding: 1em 2em;
      @include mat-elevation(2);
    }

    .controls {
      padding: 1em 0;

      rea-button:nth-child(1) {
        padding: 0 1em 0 0;
      }
    }
  }

  .published {
    grid-area: published;

    .published-header {
      background: #4dc4d3;
      padding: 1em 2em;
      @include mat-elevation(2);
    }
  }

  .resend {
    grid-area: resend;

    .resend-header {
      background: $white-smoke;
      padding: 1em 2em;
      @include mat-elevation(2);
    }
  }

  .events {
    grid-area: events;

    .events-header {
      color: white;
      background: #304047;
      padding: 1em 2em;
      @include mat-elevation(2);
    }

    .associated-event-card {
      background: white;
      padding: 1em 2em;
      @include container-shadow;
    }
  }

  tr.mat-row {
    height: 4em;
  }
}

@media ( width <= $breakpoint-xl) {
  .template-management {
    padding: 1em;
    grid-template-columns: repeat( auto-fit, minmax(0px, 1fr));
    grid-template-areas:
      "drafts"
      "right-column";
  }
}
