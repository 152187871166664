@import '_variables.scss';

.drop-zone {
  background: $alice-blue;
  border: 2px #D8DFE8 dashed;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  margin: 1em 0;
  padding: 1em;

  .upload-area {
    display: grid;
    grid-template-columns: auto 1fr;
    grid-template-rows: auto;
    grid-template-areas:
            "icon info"
            "icon type";
    align-items: center;

    .upload-icon {
      grid-area: icon;
      display: flex;
      justify-content: center;
      padding: 0 1em;
    }

    .upload-info {
      grid-area: info;
      margin: 1em 0;
      font-weight: bold;

      .file-upload {
        cursor: pointer;
        color: $primary;
      }
    }

    .upload-type {
      grid-area: type;
      margin: 1em 0;
    }
  }

  .file-input {
    display: none;
  }
}

.file-over {
  border: 2px #73a1dd dashed;
}

.file {
  background: #F7FAFD;
  border: 2px #D8DFE8 dashed;
  border-radius: 4px;
  display: grid;
  grid-template-columns: 1fr auto;
  grid-template-rows: auto;
  grid-template-areas:
        "info size"
        "progress progress";
  align-items: center;
  margin: 1em 0;
  padding: 1em;

  .file-name {
    grid-area: info;
    display: grid;
    grid-template-columns: auto 1fr;
    grid-gap: 1em;
    align-items: center;
  }

  .file-size {
    grid-area: size;
  }

  .file-progress {
    grid-area: progress;
  }
}

.file-error {
  background: #fab3ae;
  border: 2px #f54f25 dashed;
}

.file-success {
  background: #d4fdd0;
  border: 2px #46f055 dashed;
}

.upload-area, .file{
  mat-icon {
    width: 3em;
    height: 3em;
    color: $hoki;
  }
}
