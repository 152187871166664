@import 'variables';

.status-label {
  border-radius: 10%;
  padding: 0.1em 0.5em;
  font-size: 0.8em;
  color: $white;
}

.label-success {
  background-color: $success;
}

.label-resent {
  background-color: #85d1a0;
}


.label-danger {
  background-color: $danger;
}

.label-info {
  background-color: $primary;
}

.search-results {
  .mat-form-field-wrapper {
    padding: .75em 0;
  }
}

.material-icons-outlined {
  font-size: 19px;
}

.search-table {
  width: 100%;

  tr.mat-row {
    height: 70px;
  }

  th.mat-header-cell, td.mat-cell {
    padding: 0 1em;
  }

  rea-input {
    width: 90%;
  }

  .actions {
    display: grid;
    grid-template-columns: auto 1fr;
    grid-gap: 1em;
  }

  .resent-element {
    background-color: rgba($heather, .1);

    td:nth-of-type(1) {
      padding-left: 3.5em;
    }
  }

  .centered-information {
    display: flex;

    mat-icon {
      margin-right: .2em;
    }
  }
}

@media (width <= $breakpoint-lg) {

  .search-table.mat-table, .monitoring-data .mat-table {

    .mat-header-row {
      display: flex;
      flex-wrap: wrap;
      height: auto;
      position: sticky;
      top: 0;
      z-index: 2;

      th.mat-header-cell {
        display: flex;
        flex: 1;
        align-items: center;
        padding: 1rem 0.5rem;
        justify-content: space-around;


        &:not([mat-sort-header]),
        &.mat-sort-header-disabled,
        &.mat-column-actions {
          display: none;
        }
      }
    }

    .mat-row {
      height: auto;

      td.mat-cell {
        display: grid;
        grid-template-columns: 40% 60%;
        padding: 0.5rem;
        text-align: left;
        word-break: break-word;
        justify-items: flex-start;
        font-size: .9em;


        & > * {
          grid-column: 2;
        }

        &:before {
          content: attr(data-column);
          text-align: left;
        }
      }

      &:last-of-type td:last-of-type {
        border: none;
      }

      &:not(:last-of-type) td:last-of-type {
        border-bottom: 2em solid $snow;
      }
    }
  }
}
